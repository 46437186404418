import { Checkbox } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { useAuth } from "@/hooks/useAuth";
import { pdf } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { BsExclamationCircle } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { FaChartLine, FaExternalLinkAlt, FaShippingFast } from "react-icons/fa";
import { HiWrenchScrewdriver } from "react-icons/hi2";
import { IoMdOptions } from "react-icons/io";
import {
  MdBusinessCenter,
  MdOutlineFileDownload,
  MdPerson,
  MdWarning,
} from "react-icons/md";
import { RiBriefcase4Fill } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import { PageHeader } from "../../components/layout/Page";
import { Badge } from "../../components/ui/Badge";
import { LoadingDots } from "../../components/ui/Loading";
import { Modal } from "../../components/ui/Modal";
import Tabs from "../../components/ui/Tabs";
import { API_URL } from "../../config/routes";
import { clsxm, formatDate } from "../../utils.js";
import OfferPDF from "./OfferPDF";

const formatNumber = (number) => {
  return Intl.NumberFormat("it-IT", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};

const extractOptions = (configurations) => {
  return Object.keys(configurations[0]).reduce((options, key) => {
    options[key] = [...new Set(configurations.map((config) => config[key]))];
    // Return options sorted by ascending order
    return {
      ...options,
      [key]: options[key].sort((a, b) => a - b),
    };
  }, {});
};

function RadioButtonCard({ label, value, name, checked, onClick }) {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <input
        id={`${name}-${value}`}
        type="radio"
        value={value}
        name={name}
        className="peer hidden"
        checked={checked}
        readOnly
      />
      <label
        htmlFor={`${name}-${value}`}
        className="flex cursor-pointer text-sm font-semibold text-slate-600 bg-gray-100 rounded p-3 py-2 w-full items-center justify-center hover:ring-2 hover:ring-gray-200 peer-checked:bg-blue-600 peer-checked:text-white peer-checked:ring peer-checked:ring-blue-400 peer-checked:border-0 border-b select-none"
        onClick={onClick}
      >
        {label}
      </label>
    </div>
  );
}

function OptionsRow({ prompt, children }) {
  return (
    <div className="flex flex-row mt-4">
      <div className="flex flex-col w-full">
        <h4 className="text-sm font-bold text-black/70 underline decoration-1 decoration-slate-200">
          {prompt}
        </h4>
        <div className="flex flex-row flex-wrap text-sm mt-2">{children}</div>
      </div>
    </div>
  );
}

function OfferConfigurator({ offers, selectedOffer, setSelectedOffer }) {
  const options = extractOptions(offers);
  const [configuration, setConfiguration] = useState({
    distance: offers[0].distance,
    duration: offers[0].duration,
    deposit: offers[0].deposit,
  });

  const handleConfigurationChange = (key, value) => {
    setConfiguration((prevConfiguration) => ({
      ...prevConfiguration,
      [key]: value,
    }));
  };

  useEffect(() => {
    // Get the offer that matches the current configuration
    const offer = offers.find(
      (offer) =>
        offer.distance === configuration.distance &&
        offer.duration === configuration.duration &&
        offer.deposit === configuration.deposit
    );
    setSelectedOffer(offer);
  }, [configuration, setSelectedOffer]);

  return (
    <div className="flex-col p-4 md:p-6 md:pr-0 2xl:pr-48">
      {/* Header */}
      <h1 className="text-2xl font-bold text-zinc-700">
        Configura la tua offerta
      </h1>

      {/* Distance row */}
      <OptionsRow prompt={"1. Scegli la percorrenza"}>
        {options.distance.map((distance, index) => (
          <div className="w-auto p-1" key={index}>
            <RadioButtonCard
              key={index}
              value={distance}
              name="distance"
              checked={configuration.distance === distance}
              label={
                Intl.NumberFormat("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(distance) + " km/anno"
              }
              onClick={() => handleConfigurationChange("distance", distance)}
            />
          </div>
        ))}
      </OptionsRow>

      {/* Duration row */}
      <OptionsRow prompt={"2. Scegli la durata del contratto"}>
        {options.duration.map((duration, index) => (
          <div className="w-auto p-1" key={index}>
            <RadioButtonCard
              key={index}
              value={duration}
              name="duration"
              checked={configuration.duration === duration}
              label={`${duration} mesi`}
              onClick={() => handleConfigurationChange("duration", duration)}
            />
          </div>
        ))}
      </OptionsRow>

      {/* Deposit row */}
      <OptionsRow prompt={"3. Scegli l'anticipo (IVA esclusa)"}>
        {options.deposit.map((deposit, index) => (
          <div className="w-auto p-1" key={index}>
            <RadioButtonCard
              key={index}
              value={deposit}
              name="deposit"
              checked={configuration.deposit === deposit}
              label={Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(deposit)}
              onClick={() => handleConfigurationChange("deposit", deposit)}
            />
          </div>
        ))}
      </OptionsRow>
    </div>
  );
}

function OfferConfigurationBadge({ id, className }) {
  const map = {
    private: {
      label: "Per privati",
      icon: <MdPerson />,
      className: "bg-blue-100 text-blue-900 ring-blue-200",
      tooltip: "Questa offerta è disponibile per privati",
    },
    company: {
      label: "Per aziende",
      icon: <MdBusinessCenter />,
      className: "bg-green-100 text-green-900 ring-green-200",
      tooltip: "Questa offerta è disponibile per aziende",
    },
    fastDelivery: {
      label: "Consegna veloce (30-60gg)",
      icon: <FaShippingFast />,
      className: "bg-red-100 text-red-800 ring-red-200",
      tooltip: "Questa offerta è disponibile in consegna veloce (30-60gg)",
    },
    interpolated: {
      label: "Canone previsto",
      icon: <FaChartLine />,
      className: "bg-yellow-100 text-yellow-800 ring-yellow-200",
      tooltip:
        "Il prezzo di questa offerta è calcolato da un algoritmo che usa i canoni di altre configurazioni simili per fare una stima del costo del noleggio in base alla configurazione scelta",
    },
  };
  return (
    <Badge
      className={clsxm("text-sm", map[id].className, className)}
      icon={map[id].icon}
      label={map[id].label}
      tooltip={map[id].tooltip}
    />
  );
}

function OfferConfigurationBadges({ selectedOffer, className }) {
  const badges = [
    selectedOffer?.target !== "all" && selectedOffer?.target,
    selectedOffer?.target === "all" && "private",
    selectedOffer?.target === "all" && "company",
    selectedOffer?.fast_delivery && "fastDelivery",
    // selectedOffer?.is_interpolated && "interpolated",
  ].filter(Boolean);
  return (
    <div className={clsxm("flex flex-row flex-wrap w-[65%]", className)}>
      {badges.map((badge, index) => (
        <div className="mr-3 mb-3" key={index}>
          <OfferConfigurationBadge id={badge} />
        </div>
      ))}
    </div>
  );
}

function OfferConfigurationResults({ data, selectedOffer }) {
  if (!selectedOffer)
    return (
      <div className="flex flex-col items-center justify-center w-full h-full p-5 py-10">
        <span className="text-5xl font-bold text-red-700">
          <BsExclamationCircle />
        </span>
        <span className="ml-2 mt-4 text-zinc-600 font-semibold">
          La configurazione selezionata non è disponibile
        </span>
        <span className="ml-2 mt-1 text-zinc-700 text-sm text-center px-5">
          Prova un'altra configurazione o contatta direttamente il back-office
          Genoleggia per un preventivo personalizzato.
        </span>
      </div>
    );

  return (
    <div className="p-6">
      {/* Monthly rate */}
      <div className="flex flex-col items-start text-2xl">
        <h1 className="text-2xl font-bold text-zinc-700">Canone mensile</h1>

        <span className="mt-2 flex items-center">
          {selectedOffer.is_interpolated ? (
            <b className="text-red-700">Configurazione non disponibile</b>
          ) : (
            <>
              <span>€</span>
              <b>
                {Intl.NumberFormat("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(selectedOffer.price)}
              </b>
              /mese
            </>
          )}
        </span>
        <span className="block text-sm text-black/60 mt-1">
          {selectedOffer.is_interpolated ? (
            <span>
              Apri una nuova{" "}
              <Link to="/deals/new" className="text-blue-700 hover:underline">
                Trattativa <FaExternalLinkAlt className="inline text-sm" />
              </Link>{" "}
              per un preventivo personalizzato.
            </span>
          ) : (
            <>
              <span>€</span>
              {Intl.NumberFormat("it-IT", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(selectedOffer.price * 1.22) + "/mese IVA inclusa"}
            </>
          )}
        </span>
      </div>

      {/* Badges */}
      <OfferConfigurationBadges
        selectedOffer={selectedOffer}
        className="mb-3 mt-5"
      />

      {/* Included services */}
      <div className="flex flex-col items-start mt-1">
        <h4 className="mb-1 text-sm font-bold text-black/70 underline decoration-1 decoration-slate-200">
          Che include
        </h4>
        {data.included_services.map((service, index) => (
          <span className="text-sm text-black/60" key={index}>
            {index + 1}. {service.name}
          </span>
        ))}
      </div>

      {/* Download offer pdf */}
      <DownloadOfferBtn
        data={data}
        selectedOffer={selectedOffer}
        className="mt-4"
      />
    </div>
  );
}

function DownloadOfferWarningModalBody({ onSubmit }) {
  const [termsAccepted, setTermsAccepted] = useState(false);

  return (
    <>
      <div className="p-5 px-7">
        <p className="text-zinc-800 bg-yellow-100 rounded-lg p-5 flex flex-row">
          <span className="h-auto w-36 flex text-yellow-600 mr-5 ml-2">
            <MdWarning className="w-full my-auto h-full" />
          </span>
          <span>
            L'offerta scaricata <b>non è un preventivo</b>. Di conseguenza è
            possibile che a causa di forte domanda o di variazioni di mercato il
            prezzo finale e le condizioni dell'offerta possano differire da come
            indicato.
          </span>
        </p>
      </div>
      <div className="flex justify-between items-center p-5 px-7 border-t border-zinc-200">
        <Checkbox
          name="terms-checkbox"
          label="Ho letto e accetto i termini e le condizioni"
          checked={termsAccepted}
          onChange={(e) => {
            setTermsAccepted(e.target.checked);
          }}
        />
        <button
          onClick={() => {
            setTermsAccepted(false);
            onSubmit();
          }}
          disabled={!termsAccepted}
          className={clsxm(
            "bg-blue-600 text-white active:bg-blue-800 font-bold flex items-center text-lg px-3 py-2 pr-5 rounded-lg hover:ring-4 ring-blue-300",
            !termsAccepted &&
              "cursor-not-allowed bg-blue-400 active:bg-blue-400"
          )}
        >
          <span className="inline-block mr-2">
            <MdOutlineFileDownload />
          </span>
          Scarica offerta
        </button>
      </div>
    </>
  );
}

function DownloadOfferBtn({ data, selectedOffer, className }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [warningIsOpen, setWarningIsOpen] = useState(false);

  function downloadOfferDocument() {
    setIsDownloading(true);
    var doc = <OfferPDF data={data} selectedOffer={selectedOffer} />;

    const filename = [
      new Date().toLocaleDateString("it-IT"),
      data.vehicle.slug,
      selectedOffer.distance,
      selectedOffer.duration,
    ].join("-");

    // Download the pdf
    pdf(doc)
      .toBlob()
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `${filename}.pdf`;
        a.click();
        setTimeout(() => {
          setIsDownloading(false);
        }, 1000);
      });
  }

  return (
    <>
      <Modal
        onClose={() => setWarningIsOpen(false)}
        isOpen={warningIsOpen}
        title="Termini e condizioni"
      >
        <DownloadOfferWarningModalBody
          onSubmit={() => {
            setWarningIsOpen(false);
            downloadOfferDocument();
          }}
        />
      </Modal>
      <Button
        disabled={isDownloading || selectedOffer.is_interpolated}
        className={clsxm(
          "text-lg p-5 pr-7 hover:ring-4 ring-blue-300",
          className
        )}
        onClick={() => {
          if (isDownloading) return;
          setWarningIsOpen(true);
        }}
      >
        <span className="inline-block mr-2">
          {!isDownloading ? (
            <MdOutlineFileDownload />
          ) : (
            <CgSpinner className="animate-spin" />
          )}
        </span>
        Scarica offerta
      </Button>
    </>
  );
}

function DetailsInfoSpan({ title, children }) {
  return (
    <span>
      <span className="font-semibold text-zinc-700">{title}</span>:{" "}
      {children ? (
        children
      ) : (
        <span className="text-black/30 underline">Non disponibile</span>
      )}
    </span>
  );
}

function DetailsTabs({ data, selectedOffer }) {
  const { user } = useAuth();
  const commission = selectedOffer?.commission;
  const commissionFactor = (0.65 * user.commission_percentage || 1) / 100;

  return (
    <Tabs.Group className="border-b" defaultIndex={0}>
      <Tabs.Item title="Specifiche" icon={<HiWrenchScrewdriver />}>
        <div className="flex flex-row flex-wrap text-sm">
          {Object.keys(data.vehicle.details).map(
            (key, index) =>
              // If the value isn't null or undefined, render the element
              data.vehicle.details?.[key]?.value && (
                <div
                  key={index}
                  className="flex flex-col bg-gray-50 p-4 py-3 border m-1 rounded-lg"
                >
                  <span className="font-bold">
                    {data.vehicle.details[key]["name"]}
                  </span>
                  <span>
                    {data.vehicle.details[key]["value"]}{" "}
                    {data.vehicle.details[key]["unit"]}
                  </span>
                </div>
              )
          )}
        </div>
      </Tabs.Item>
      <Tabs.Item title="Accessori" icon={<IoMdOptions />}>
        <div className="flex flex-row flex-wrap text-sm">
          {data.vehicle.stock_equipment.map((equipment, index) => (
            <div
              key={index}
              className="flex flex-col flex-grow bg-gray-50 p-4 py-3 border m-1 rounded-lg"
            >
              <span className="w-auto">{equipment}</span>
            </div>
          ))}
        </div>
      </Tabs.Item>
      <Tabs.Item title="Dati commerciali" icon={<RiBriefcase4Fill />}>
        <div className="flex flex-col p-4 pt-0">
          <ul className="text-sm text-zinc-700">
            <li>
              <DetailsInfoSpan title="Fornitore">
                {selectedOffer?.vendor}
              </DetailsInfoSpan>
            </li>
            <li>
              <DetailsInfoSpan title="Intermediario">
                {selectedOffer?.intermediary && (
                  <span>
                    {selectedOffer?.intermediary}{" "}
                    {selectedOffer?.intermediary_offer_url && (
                      <>
                        (
                        <a
                          className="text-blue-700 hover:underline"
                          href={selectedOffer?.intermediary_offer_url}
                          target="_blank"
                        >
                          Riferimento
                        </a>
                        )
                      </>
                    )}
                  </span>
                )}
              </DetailsInfoSpan>
            </li>
            <li>
              <DetailsInfoSpan title="Provvigione">
                {user.network_id === 20 && commission >= 200 ? ( // This is for the FIGENPA users
                  <span className="inline-flex items-center">
                    €{formatNumber(commission * 0.95 * commissionFactor)}
                  </span>
                ) : commission && commission >= 200 ? (
                  <span className="inline-flex items-center">
                    €{formatNumber(commission * commissionFactor)}
                  </span>
                ) : (
                  <span className="inline-flex items-center italic text-zinc-400">
                    Non disponibile
                  </span>
                )}
              </DetailsInfoSpan>
            </li>
            <li>
              <DetailsInfoSpan title="Offerta valida fino al">
                {formatDate(selectedOffer?.valid_until)}
              </DetailsInfoSpan>
            </li>
          </ul>
          {commission && (
            <span className="text-xs text-black/60 mt-2 ">
              La provvigione varia in base al fornitore, intermediario, tipo di
              veicolo e configurazione dell'offerta.
            </span>
          )}
        </div>
      </Tabs.Item>
    </Tabs.Group>
  );
}

export default function OfferDetailsPage() {
  const { slug } = useParams();
  const [selectedOffer, setSelectedOffer] = useState({});
  const { data, error, isLoading } = useSWR(`${API_URL}/offers/${slug}`, {
    // No need to revalidate on focus since it's cached
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (data) {
      setSelectedOffer(data.offers[0]);
    }
  }, [data]);

  if (isLoading)
    return (
      <div className="h-screen w-full flex items-center justify-center bg-stone-50/50 text-zinc-600 text-3xl">
        <LoadingDots />
      </div>
    );
  if (error) return <div>Something went wrong...</div>;

  return (
    <div className="animate-fadeIn">
      {/* Page Header */}
      <PageHeader
        className="p-4 border-b mb-0" // Do this for now
        breadcrumbs={[
          { label: "Offerte", href: "/offers" },
          { label: data.vehicle.name },
        ]}
      />

      {/* Image */}
      <div className="grid grid-cols-1 md:grid-cols-2 divide-x border-b 2xl:h-1/2">
        <div className="flex flex-col items-center justify-center">
          <img src={data.vehicle.model.image} alt="car image" />
          <span className="pb-6 text-[11px] md:w-[60%] text-black/50 text-center">
            Il veicolo in foto è puramente rappresentativo e potrebbe non
            corrispondere al colore, anno di produzione o allestimento esatto
            del veicolo in offerta.
          </span>
        </div>
        <OfferConfigurator
          offers={data.offers}
          selectedOffer={selectedOffer}
          setSelectedOffer={setSelectedOffer}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 divide-x border-b">
        <DetailsTabs data={data} selectedOffer={selectedOffer} />
        <div className="order-first md:order-last">
          <OfferConfigurationResults
            data={data}
            selectedOffer={selectedOffer}
          />
        </div>
      </div>
    </div>
  );
}
