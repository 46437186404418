import {
  Checkbox,
  FormikCheckbox,
  FormikInput,
} from "@/components/form/inputs";
import { BytescaleUploadButton } from "@/components/form/inputs/BytescaleUpload";
import { Button } from "@/components/ui/Button";
import { EphemeralFileChip } from "@/components/ui/FileChip";
import { verticalTableFromRows } from "@/components/ui/Table";
import { API_URL } from "@/config/routes";
import { fetcher } from "@/utils";
import { Form, Formik } from "formik";
import { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormikDealSelect } from "../deals/DealSelect";

const getFilenameFromUrl = (url) =>
  url.split("/")?.pop()?.split("-")?.slice(1).join("-");

const initialValues = {
  deal_id: "",
  vehicle_name: "",
  distance: "",
  duration: "",
  deposit: "",
  price: "",
  extra_distance_price: "",
  renting_company: "",
  renting_intermediary: "",
  genoleggia_commission: "",
  partner_commission: "",
  contract_close_date: new Date().toISOString().split("T")[0],
  date_of_invoice_emission: null,
  client_delivery_date: null,
  genoleggia_commission_received_date: null,
  partner_commission_received_date: null,
  tires_included: false,
  replacement_car_included: false,
};

function UploadDocuments({ documents, setDocuments }) {
  return (
    <div className="flex flex-wrap gap-2">
      {documents.length > 0 && (
        <>
          {documents.map((documentUrl) => (
            <EphemeralFileChip
              key={documentUrl}
              name={getFilenameFromUrl(documentUrl)}
              onDelete={() =>
                setDocuments((prev) =>
                  prev.filter((prevUrl) => prevUrl !== documentUrl)
                )
              }
            />
          ))}
        </>
      )}
      <BytescaleUploadButton
        maxFileCount={16}
        onComplete={(files) => {
          setDocuments((prev) => [
            ...prev,
            ...files.map((file) => file.fileUrl),
          ]);
        }}
      >
        Carica {documents.length > 0 ? "altri documenti" : "documenti"}
      </BytescaleUploadButton>
    </div>
  );
}

function AdditionalServicesCheckboxes() {
  return (
    <div className="flex flex-col gap-2">
      <FormikCheckbox label="Gomme" name="tires_included" />
      <FormikCheckbox
        label="Veicolo sostitutivo"
        name="replacement_car_included"
      />
    </div>
  );
}

export default function ContractCreateForm() {
  const navigate = useNavigate();
  const [goToContract, setGoToContract] = useState(false);
  const [documents, setDocuments] = useState([]);

  const handleSubmit = (values, { resetForm }) => {
    fetcher(`${API_URL}/contracts`, {
      method: "POST",
      body: JSON.stringify({ ...values, documents: documents }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            toast.success(
              <span>
                Contratto <b>{data.pretty_id}</b> creato con successo!
              </span>
            );
            if (goToContract) navigate(`/contracts/${data.id}`);
            resetForm();
            setDocuments([]);
          });
        } else {
          toast.error("Errore nella creazione del contratto");
        }
      })
      .catch((error) => {
        toast.error("Errore nella creazione del contratto: " + error.message);
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, dirty, resetForm }) => (
        <Form>
          {/* Make table with helper method */}
          {verticalTableFromRows(
            {
              labelClassName: "w-[30%]",
              valueClassName: "whitespace-normal",
            },
            [
              {
                label: "Trattativa associata",
                subLabel:
                  "Seleziona la trattativa associata a questo contratto. La lista mostra solo le trattative concluse.",
                value: <FormikDealSelect name="deal_id" required />,
              },
              {
                label: "Veicolo",
                subLabel: "Nome o descrizione del veicolo",
                value: (
                  <FormikInput
                    name="vehicle_name"
                    placeholder="Fiat Panda, BMW X5, ..."
                  />
                ),
              },
              {
                label: "Km (annui)",
                value: (
                  <FormikInput
                    name="distance"
                    type="number"
                    required
                    min={0}
                    max={100000}
                    step={1000}
                    placeholder="Inserisci il numero di km percorsi annualmente"
                  />
                ),
              },
              {
                label: "Durata (mesi)",
                value: (
                  <FormikInput
                    name="duration"
                    type="number"
                    min={12}
                    max={60}
                    step={12}
                    required
                    placeholder="Inserisci il numero di mesi del contratto"
                  />
                ),
              },
              {
                label: "Canone (€)",
                value: (
                  <FormikInput
                    name="price"
                    type="number"
                    min={0}
                    step={0.01}
                    placeholder="Inserisci la rata mensile"
                    required
                  />
                ),
              },
              {
                label: "Anticipo (€)",
                value: (
                  <FormikInput
                    name="deposit"
                    required
                    type="number"
                    min={0}
                    step={0.01}
                    placeholder="Inserisci l'anticipo"
                  />
                ),
              },
              {
                label: "Costo km extra (€)",
                value: (
                  <FormikInput
                    name="extra_distance_price"
                    type="number"
                    min={0}
                    step={0.01}
                    placeholder="Inserisci il costo per km extra"
                  />
                ),
              },
              {
                label: "Società di noleggio",
                value: (
                  <FormikInput
                    required
                    name="renting_company"
                    placeholder="Arval, Leasys, ..."
                  />
                ),
              },

              {
                label: "Società di intermediazione",
                value: (
                  <FormikInput
                    name="renting_intermediary"
                    placeholder="Genoleggia, Horizon, Car Necting, ..."
                  />
                ),
              },
              {
                label: "Provvigioni Genoleggia",
                value: (
                  <FormikInput
                    required
                    name="genoleggia_commission"
                    placeholder="Provvigioni totali che Genoleggia riceve"
                    min={0}
                    step={0.01}
                    type="number"
                    suffix="€"
                  />
                ),
              },
              {
                label: "Provvigioni Socio",
                value: (
                  <FormikInput
                    name="partner_commission"
                    placeholder="Provvigioni che il Socio riceve"
                    min={0}
                    step={0.01}
                    type="number"
                    suffix="€"
                  />
                ),
              },
              {
                label: "Data firma contratto",
                subLabel:
                  "Seleziona la data in cui è stato chiuso il contratto di noleggio.",
                value: (
                  <FormikInput
                    required
                    name="contract_close_date"
                    type="date"
                  />
                ),
              },
              {
                label: "Data emissione fattura",
                subLabel:
                  "Seleziona la data in cui è stata emessa la fattura alla società o all'intermediario del noleggio.",
                value: (
                  <FormikInput name="date_of_invoice_emission" type="date" />
                ),
              },
              {
                label: "Data consegna al cliente",
                value: <FormikInput name="client_delivery_date" type="date" />,
              },
              {
                label: "Data incasso provvigioni Genoleggia",
                value: (
                  <FormikInput
                    name="genoleggia_commission_received_date"
                    type="date"
                  />
                ),
              },
              {
                label: "Data incasso provvigioni Socio",
                value: (
                  <FormikInput
                    name="partner_commission_received_date"
                    type="date"
                  />
                ),
              },
              {
                label: "Servizi aggiuntivi",
                value: <AdditionalServicesCheckboxes />,
              },
              {
                label: "Documenti contrattuali",
                value: (
                  <UploadDocuments
                    documents={documents}
                    setDocuments={setDocuments}
                  />
                ),
              },
            ]
          )}
          <div className="flex flex-row justify-between pt-4">
            <Checkbox
              label="Vai al nuovo contratto dopo la creazione"
              className="text-sm text-zinc-600 px-4"
              disabled={!dirty || isSubmitting}
              checked={goToContract}
              onChange={() => setGoToContract(!goToContract)}
            />
            <div className="flex items-center gap-2">
              <Button
                type="reset"
                className="bg-gray-300 hover:bg-gray-400"
                onClick={() => resetForm()}
              >
                Reset
              </Button>
              <Button
                type="submit"
                icon={<BsPlusCircle />}
                disabled={!dirty || isSubmitting}
              >
                Crea contratto
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
